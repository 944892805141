import {
  TransactionsWithoutTicketsSentService
} from '../../../support/core/services/TransactionsWithoutTicketsSentService';

export enum TableExportTypes {
  MoviesRequests = 'MoviesRequests',
  CampaignsStatistics = 'CampaignsStatistics',
  Cinemas = 'Cinemas',
  Distributors = 'Distributors',
  Employees = 'Employees',
  TechnicalDevices = 'TechnicalDevices',
  ScannerDevices = 'ScannerDevices',
  ReleaseNotes = 'ReleaseNotes',
  ActivationOrders = 'ActivationOrders',
  Invoices = 'Invoices',
  Mandators = 'Mandators',
  News = 'News',
  SubscribersStatistics = 'SubscribersStatistics',
  OnlineTransactions = 'OnlineTransactions',
  AllTransactions = 'AllTransactions',
  Sessions = 'Sessions',
  Snippets = 'Snippets',
  BouncedEmails = 'BouncedEmails',
  EmployeesActivity = 'EmployeesActivity',
  FiskalyOverview = 'FiskalyOverview',
  FisklayMandatorDetails = 'FisklayMandatorDetails',
  SoldTicketByMandators = 'SoldTicketByMandators',
  CancelledVouchers = 'CancelledVouchers',
  ControlTicketQuantityTurnover = 'ControlTicketQuantityTurnover',
  ControlTicketQuantityTurnoverForYear = 'ControlTicketQuantityTurnoverForYear',
  MandatorFiskalyClients = 'MandatorFiskalyClients',
  MandatorFees = 'MandatorFees',
  OnlineFees = 'OnlineFees',
  TseFees = 'TseFees',
  CancelledVouchersByMandator = 'TseFees',
  TransactionsWithoutTicketsSent = 'TransactionsWithoutTicketsSent'
}
