export enum Actions {
  ADD_SCANNER = 'add_scanner',
  ADD_TSE = 'add_tse',
  PLANNING = 'planning',
  ACTIVATION_ORDERS = 'activation_orders',
  INVOICES = 'invoices',
  FFA = 'ffa',
  ADD_EMPLOYEES = 'add_employees',
  EDIT_EMPLOYEES = 'edit_employees',
  EDIT_MANDATOR_DETAILS = 'edit_mandator_details',
  CONTROLLING = 'controlling',
  AWS_DASHBOARD = 'aws_dashboard',
  BUTTON_RESEND_EMAILS = 'button_resend_emails'
}
